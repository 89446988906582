<template>

    <div v-if="mobile" class="w-full h-full px-3 py-4">
        
        <All model="Groupinfo" :immediate="true" v-slot="{data: infodata, loading: infoloading}" @update:data="setLimitMonth()">

            <div v-if="!infoloading" :class="{'h-full grid grid-cols-3 gap-3 overflow-hidden': !mobile, 'h-auto': mobile}">

                <div class="h-full w-full" :class="{'col-span-2 overflow-hidden': !mobile}">

                    <div class="h-full w-full rounded-xl shadow_module flex flex-col p-2 pb-6 mb-4 overflow-auto" :class="'bg-module-'+mode">

                        <div v-if="permisions" class="absolute bottom-0 right-0 h-auto flex w-auto z-30 m-2" >

                            <div class="h-10 w-10 rounded-full bg-red flex flex-row justify-center items-center" @click="toTops()">
                                <i class="mdi mdi-filter-variant text-white text-xl font-semibold"></i>
                            </div>

                        </div>

                        <All model="Groupview" :immediate="true" :query="query" v-slot="{ data, loading }">

                            <div v-if="!loading">

                                <div v-if="!preciomedio && !margen" class="h-auto w-full mt-2" :class="{'grid grid-cols-2 gap-2': !facturation && !metroscuadrados}">
                                    <billing v-if="!metroscuadrados"
                                    :data="data.BubbleSale"
                                    model="Groupcompanydetails"
                                    :config="configFacturacion"
                                    :lastSale="infodata.LastSaleDate"
                                    @openFacturation="openFacturation"
                                    />
                                    <meters v-if="!facturation" :data="data.BubbleMeter" model="Groupcompanydetails"
                                        :config="configMetros" :lastSale="infodata.LastSaleDate" @openMeters="openMeters" />
                                </div>

                                <div v-if="!facturation && !metroscuadrados" class="h-auto w-full mt-2" :class="{'grid grid-cols-2 gap-2': !margen && !preciomedio}">
                                    <halfprice v-if="!margen" :data="data.BubbleMediumPrice" model="Groupcompanydetails"
                                        :config="configPreciomedio" :lastSale="infodata.LastSaleDate" @fromhalfprice="openHalfPrice"
                                        :isOpen="preciomedio" />
                                    <margin v-if="!preciomedio" :data="data.BubbleMargin" model="Groupcompanydetails"
                                        :config="configMargen" :lastSale="infodata.LastSaleDate" @openMargen="openMargen"
                                        :isOpen="margen" />
                                </div>

                                <All model="Groupotherbubbles" :immediate="true" :query="query"
                                    v-slot="{data: otherbubbles, loading: loadingbubbles23}">
                                    <div v-if="!loadingbubbles23 && !margen && !preciomedio" class="h-auto">
                                        <div v-if="!facturation && !metroscuadrados && !preciomedio && !margen"
                                            class="h-full grid grid-cols-2 gap-2 mt-2">
                                            <orders :data="otherbubbles.BubblePendingOrder" v-if="otherbubbles.BubblePendingOrder"
                                                :lastSale="infodata.LastSaleDate" />
                                            <deliverynotes :data="otherbubbles.BubbleDeliveryNoteOrders"
                                                v-if="otherbubbles.BubbleDeliveryNoteOrders" />
                                        </div>
                                    </div>
                                </All>

                            </div>

                            <div v-else class="h-32 relative" :class="'bg-module-'+mode">

                                <Loader :loading="loading" />

                            </div>
                            
                        </All>

                    </div>
                
                </div>

                <div class="h-full w-full" :class="{'overflow-auto': !mobile}">

                    <All model="Groupcompanies" :immediate="true" :query="query" :config="config"
                    v-slot="{data: datacompany, loading: loadingcompany}">
                    
                        <div v-if="!loadingcompany" class="w-full relative" :class="{'h-full': !mobile, 'h-auto': mobile}">

                            <div v-if="datacompany && !loadingcompany" class="w-full pb-6" :class="{'h-full': !mobile, 'h-auto ': mobile}">

                                <company v-for="(el, index) in datacompany" :key="'company ' + index" :data="el" :index="index"
                                :infodata="infodata" @goToCompany="goToCompany" />

                                <div v-if="!mobile" class="h-12"></div>

                            </div>
                            
                        </div>

                        <div v-else class="h-32 relative" :class="'bg-module-'+mode">

                            <Loader :loading="loadingcompany" />

                        </div>
                    
                    </All>
                
                </div>

            </div>

            <div v-else class="h-32 relative" :class="'bg-module-'+mode">

                <Loader :loading="infoloading" />

            </div>

        </All>

    </div>

    <div v-else class="h-full w-full p-2 overflow-hidden">
        
       

        <All model="Groupinfo" :immediate="true" v-slot="{data: infodata, loading: infoloading}"  @update:data="setLimitMonth">
       
            
            
            
            <div v-if="!infoloading" class="h-full grid grid-cols-3 gap-3 overflow-hidden">
                
                <div class="h-full w-full col-span-2 overflow-hidden">

                    <div class="h-full w-full rounded-xl shadow_module flex flex-col p-2 pb-6 mb-4 overflow-auto" :class="'bg-module-'+mode">

                        <div v-if="permisions" class="absolute bottom-0 right-0 h-auto flex w-auto z-30 m-2">

                            <div class="h-10 w-10 rounded-full bg-red flex flex-row justify-center items-center" @click="toTops()">
                                <i class="mdi mdi-filter-variant text-white text-xl font-semibold"></i>
                            </div>

                        </div>

                        <All model="Groupview" :immediate="true" :query="query" v-slot="{ data, loading }" >

                            <div v-if="!loading" class="h-full w-full flex flex-col">

                                <div v-if="!preciomedio && !margen" class="h-full w-full" :class="{'grid grid-cols-2 gap-2': !facturation && !metroscuadrados}">

                                    <billing v-if="!metroscuadrados"
                                    :data="data.BubbleSale"
                                    model="Groupcompanydetails"
                                    :config="configFacturacion"
                                    :lastSale="infodata.LastSaleDate"
                                    @openFacturation="openFacturation"
                                    />

                                    <meters v-if="!facturation" :data="data.BubbleMeter" model="Groupcompanydetails"
                                    :config="configMetros" :lastSale="infodata.LastSaleDate" @openMeters="openMeters" />

                                </div>

                                <div v-if="!facturation && !metroscuadrados" class="h-full w-full mt-2" :class="{'grid grid-cols-2 gap-2': !margen && !preciomedio}">
                                
                                    <halfprice v-if="!margen" :data="data.BubbleMediumPrice" model="Groupcompanydetails"
                                    :config="configPreciomedio" :lastSale="infodata.LastSaleDate" @fromhalfprice="openHalfPrice"
                                    :isOpen="preciomedio"/>

                                    <margin v-if="!preciomedio" :data="data.BubbleMargin" model="Groupcompanydetails"
                                    :config="configMargen" :lastSale="infodata.LastSaleDate" @openMargen="openMargen"
                                    :isOpen="margen" />
                                
                                </div>

                                <div class="h-full w-full">

                                    <All model="Groupotherbubbles" :immediate="true" :query="query"
                                    v-slot="{data: otherbubbles, loading: loadingbubbles23}">

                                        <div v-if="!loadingbubbles23 && !margen && !preciomedio" class="h-full">

                                            <div v-if="!facturation && !metroscuadrados && !preciomedio && !margen"
                                                class="h-full grid grid-cols-2 gap-2 mt-2">

                                                <orders :data="otherbubbles.BubblePendingOrder" v-if="otherbubbles.BubblePendingOrder"
                                                :lastSale="infodata.LastSaleDate" />

                                                <deliverynotes :data="otherbubbles.BubbleDeliveryNoteOrders"
                                                v-if="otherbubbles.BubbleDeliveryNoteOrders" />

                                            </div>

                                        </div>

                                    </All>

                                </div>

                            </div>

                            <div v-else class="h-32 relative" :class="'bg-module-'+mode">

                                <Loader :loading="loading" />

                            </div>
                            
                        </All>

                    </div>

                </div>

                <div class="h-full w-full overflow-auto">

                    <All model="Groupcompanies" :immediate="true" :query="query" :config="config"
                    v-slot="{data: datacompany, loading: loadingcompany}">
                    
                        <div v-if="!loadingcompany" class="w-full relative" :class="{'h-full': !mobile, 'h-auto': mobile}">

                            <div v-if="datacompany && !loadingcompany" class="w-full pb-6" :class="{'h-full': !mobile, 'h-auto ': mobile}">

                                <company v-for="(el, index) in datacompany" :key="'company ' + index" :data="el" :index="index"
                                :infodata="infodata" @goToCompany="goToCompany" />

                                <div v-if="!mobile" class="h-12"></div>

                            </div>
                            
                        </div>

                        <div v-else class="h-32 relative" :class="'bg-module-'+mode">

                            <Loader :loading="loadingcompany" />

                        </div>
                    
                    </All>

                </div>

            </div>

            <div v-else class="h-32 relative" :class="'bg-module-'+mode">

                <Loader :loading="infoloading" />

            </div>
        
        </All>

    </div>

</template>

<script>
import { All } from '@/api/components';
import { state, actions } from '@/store';
import solidgauge from '@/components/solidgauge.vue';
import loader from '@/components/loader.vue';
import billing from '@/components/billing.vue';
import meters from '@/components/meters.vue';
import doubleChart from '@/components/doubleChart.vue';
import halfprice from '@/components/halfprice.vue';
import margin from '@/components/margin.vue';
import orders from '@/components/orders.vue';
import deliverynotes from '@/components/deliverynotes.vue';
import company from '@/components/company.vue';
import Loader from '../../components/loader.vue';

    export default {
        name: 'representante',
        components:{
            All,
            solidgauge,
            loader,
            billing,
            meters,
            doubleChart,
            halfprice,
            margin,
            orders,
            deliverynotes,
            company,
            Loader
        },
        data() {
            return {
                facturation: false,
                metroscuadrados: false,
                preciomedio: false,
                margen: false,
                yearact: this.$moment().format('YYYY'),
                yearant: this.$moment().subtract(1, 'years').format('YYYY'),
                yearantant: this.$moment().subtract(2, 'years').format('YYYY'),
                yesterday: this.$moment().subtract(1, 'days').format("DD MMM 'YY"),
                month: this.$moment().format("MMM 'YY"),
                montprevyear: this.$moment().subtract(1, 'years').format("MMM 'YY"),
                infodata: null
            }
        },
        methods: {
            setLimitMonth(data){
                
                let dateIn = new Date(data.LastSaleDate)
                
                actions.setLimitMonth(dateIn.getMonth() +1)
                
                console.log('setLimitMonth', actions.getLimitMonth())
            },
            translate(word) {
                switch (word) {
                    case 'Facturación':
                        return this.$t("billing")
                        break;
                    case 'Metros cuadrados':
                        return this.$t("squareMeters")
                        break;
                    case 'Precio medio':
                        return this.$t("averagePrice")
                        break;
                    case 'Margen':
                        return this.$t("margin")
                        break;
                }
            },
            toTops(){ 
                this.$router.push('grouptops') 
            },
            openFacturation() {
                this.facturation = !this.facturation
                this.metroscuadrados = false
            },
            openMeters() {
                this.metroscuadrados = !this.metroscuadrados
                this.facturation = false
            },
            goToCompany(companyid, companyname, industrialgroup) {
                if (!industrialgroup) { actions.setIndustrialGroup(null) }
                else { actions.setIndustrialGroup(!industrialgroup) }
                actions.setCompanySelected(companyname)
                actions.setIdCompany(companyid)
                this.$router.push({name:'company', params: {company: companyid}})
            },
            openHalfPrice(){ 
                this.preciomedio = !this.preciomedio 
            },
            openMargen(){ 
                this.margen = !this.margen
            },
            periodfiltered() {
                switch (state.period) {
                    case 'month':
                        return this.$moment().format("MMM 'YY")
                        break;
                    case 'quarter':
                        return 'trim. ' + this.$moment().format("Q 'YY")
                        break;
                    case 'year':
                        return this.$moment().format("YYYY")
                        break;
                }
            },
            prevPeriod(){ 
                return this.$moment().subtract(1, 'years').format("YYYY") 
            }
        },
        computed: {
            monthName(){
                    return this.$moment({M: state.month -1 }).format('MMMM')
                },
            user(){ 
                return state.user 
            },
            config() {
                return {
                    data: {
                        Dimension: state.dimensionGroup
                    },
                };
            },
            configFacturacion() {
                return {
                    data: {
                        Dimension: 0,
                        period: state.period
                    },
                };
            },
            configMetros() {
                return {
                    data: {
                        Dimension: 1,
                        period: state.period
                    },
                };
            },
            configPreciomedio() {
                return {
                    data: {
                        Dimension: 2,
                        period: state.period
                    },
                };
            },
            configMargen() {
                return {
                    data: {
                        Dimension: 3,
                        period: state.period
                    },
                };
            },
            dimension(){ 
                return state.dimensionGroup 
            },
            companySelected() {
                if (state.idCompany) {
                    return state.idCompany.toUpperCase()
                }
            },
            rol(){ 
                return state.user.rol.Name 
            },
            query(){ 
                return { 
                    period: state.period, 
                    month: state.month 
                } 
            },
            dimensionName(){ 
                return state.dimensionName 
            },
            periodo(){
                switch (state.period) {
                    case 'month':
                        return this.$t("monthly")
                        break;
                    case 'quarter':
                        return this.$t("quarterly")
                        break;
                    case 'year':
                        return this.$t("annual")
                        break;
                }
            },
            permisions() {
                if (this.rol === 'Propiedad') { return true }
                else { return false }
            },
            menssagepermisions() {
                if (this.rol === 'Propiedad') { return true }
                else { return false }
            },
            mode(){
                return state.mode
            },
            mobile(){
                return state.isMobile
            }
        },
        mounted(){
            actions.setIdCompany(null);
        },
        
        watch: {
            "infodata": function(oldVal, newVal) {
                console.log(oldVal + " " + newVal);
            }
        }
    }
</script>
